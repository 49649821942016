import React, { useState } from "react";

export const Searchbar = ({ onSearch, placeholder = "Enter Search Term" }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const handleChange = ({ target }) => {
		setSearchTerm(target.value);
	};

	const handleClick = () => {
		onSearch(searchTerm);
	};

	const handleKeyPress = (event) => {
		if (event.code === "Enter") {
			onSearch(searchTerm);
		}
	};

	return (
		<div className="is-flex is-flex-direction-row is-full-width">
			<input
				className="input is-primary mr-1"
				type="text"
				placeholder={placeholder}
				onChange={handleChange}
				onKeyPress={handleKeyPress}
			/>
			<button className="button is-primary" onClick={handleClick}>
				<span className="icon">
					<i className="fas fa-search" />
				</span>
				<span>Search</span>
			</button>
		</div>
	);
};
