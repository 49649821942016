import styled from "styled-components";

const Site = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
	background: white;
`;
// background: #AFDBD8;
export default Site;
