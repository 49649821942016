import React from "react";
import RotateLoader from "react-spinners/RotateLoader";
import styles from "../styles";

export const LoadingSpinner = ({ isLoading }) => (
	<RotateLoader
		css={styles.override}
		size={30}
		margin={30}
		color={"#ED008B"}
		loading={isLoading}
	/>
);
