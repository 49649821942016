import { css } from "@emotion/react";

const override = css`
	display: block;
	margin: auto;
	border-color: red;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
`;

export default {
	override,
};
