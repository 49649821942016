import React, { useState } from "react";
import { FirstTimeLoginForm } from "./FirstTimeLoginForm";
import { LoginForm } from "./LoginForm";

const Tabs = {
	Login: "login",
	FirstTime: "firstTime",
};

export default () => {
	const [activeTab, setActiveTab] = useState(Tabs.Login);

	const isActive = (tab) => (activeTab === tab ? "is-active" : "");

	const handleTabClick = (tab) => () => {
		setActiveTab(tab);
	};

	const TabContent = () => {
		switch (activeTab) {
			case Tabs.FirstTime:
				return <FirstTimeLoginForm />;
			case Tabs.Login:
			default:
				return <LoginForm />;
		}
	};

	return (
		<div className="hero">
			<div className="hero-body">
				<div className="container has-text-centered">
					<div className="column is-8 is-offset-2">
						<div className="tabs is-large is-toggle is-fullwidth is-centered">
							<ul>
								<li className={isActive(Tabs.Login)}>
									{/* eslint-disable-next-line */}
									<a onClick={handleTabClick(Tabs.Login)}>Login</a>
								</li>
								<li className={isActive(Tabs.FirstTime)}>
									{/* eslint-disable-next-line */}
									<a onClick={handleTabClick(Tabs.FirstTime)}>First Time</a>
								</li>
							</ul>
						</div>
						<div>
							<TabContent />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
